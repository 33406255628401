import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  BetterThanOther,
  MultiColumnList,
  MultiColumnContent,
  HealthCareFooterContent,
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Event extends Component {
  render() {
    const healthCare = pageContent.subPage.healthCare;
    return (
      <Layout>
        <PageHeader image={healthCare.headerImage} />
        <Heading
          title={healthCare.title}
          overview={healthCare.overview}
          noHeadingIcon={true}
        />
        <BetterThanOther content={healthCare.betterThanOther} />
        <MultiColumnList content={healthCare.solutions} listStyle={'check'} />
        <MultiColumnList
          content={healthCare.services}
          listStyle={'dot'}
          hasBackground={true}
          listItemTextBold={true}
        />
        <MultiColumnContent
          content={healthCare.compliances}
          itemsInGroup={4}
          highlightColumnTitle={true}
        />
        <HealthCareFooterContent content={healthCare.footerContent} />
        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />
        <TechnologyPartners />
        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Event;
